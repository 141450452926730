// Import and register all your controllers from the importmap via controllers/**/*_controller
import { Application } from "@hotwired/stimulus"
import NavigationController from "./navigation_controller"
import CheckoutController from "./checkout_controller"
import RegistrationSlideshowController from "./registration_slideshow_controller"
import EditModeController from "./edit_mode_controller"
import DesignController from "./design_controller"
import LocationController from "./location_controller"
import ImagePreviewController from "./image_preview_controller"
import StripePaymentController from "./stripe_payment_controller"
import TabsController from "./tabs_controller"

const application = Application.start()
application.register("navigation", NavigationController)
application.register("checkout", CheckoutController)
application.register("registration-slideshow", RegistrationSlideshowController)
application.register("edit-mode", EditModeController)
application.register("design", DesignController)
application.register("location", LocationController)
application.register("image-preview", ImagePreviewController)
application.register("stripe-payment", StripePaymentController)
application.register("tabs", TabsController)
