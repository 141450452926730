import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["latitude", "longitude"]

  connect() {
    if (!this.hasLocationTarget) return

    this.getLocation()
  }

  getLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => this.handlePosition(position),
        (error) => this.handleError(error)
      )
    }
  }

  handlePosition(position) {
    const lat = position.coords.latitude
    const lng = position.coords.longitude

    // Update hidden form fields if they exist
    if (this.hasLatitudeTarget) {
      this.latitudeTarget.value = lat
    }
    if (this.hasLongitudeTarget) {
      this.longitudeTarget.value = lng
    }

    // Send to backend
    this.updateServerLocation(lat, lng)
  }

  handleError(error) {
    console.log("Location error:", error)
    // Fallback to IP geolocation
    this.getLocationFromIP()
  }

  async getLocationFromIP() {
    try {
      const response = await fetch('https://ipapi.co/json/')
      const data = await response.json()

      if (data.latitude && data.longitude) {
        this.handlePosition({
          coords: {
            latitude: data.latitude,
            longitude: data.longitude
          }
        })
      }
    } catch (error) {
      console.error("IP location failed:", error)
    }
  }

  async updateServerLocation(lat, lng) {
    try {
      await fetch('/api/location', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        },
        body: JSON.stringify({ latitude: lat, longitude: lng })
      })
    } catch (error) {
      console.error("Failed to update location:", error)
    }
  }
}
