import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "form",
    "paymentElement",
    "paymentRequestButton",
    "submit",
    "buttonText",
    "spinner",
    "errorMessage",
    "deliveryFields"
  ]

  static values = {
    publishableKey: String,
    clientSecret: String,
    returnUrl: String,
    amount: Number,
    title: String,
    userName: String,
    userEmail: String
  }

  connect() {
    this.stripe = Stripe(this.publishableKeyValue)
    this.initializeStripe()
    this.setupEventListeners()
  }

  async initializeStripe() {
    // Set up payment request for Apple Pay
    this.paymentRequest = this.stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: this.titleValue,
        amount: this.amountValue,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    })

    // Initialize Stripe Elements
    const options = {
      clientSecret: this.clientSecretValue,
      appearance: {
        theme: 'stripe',
        variables: {
          colorPrimary: '#4F46E5',
        }
      }
    }

    this.elements = this.stripe.elements(options)

    // Create and mount regular payment element
    this.paymentElement = this.elements.create('payment')
    this.paymentElement.mount(this.paymentElementTarget)

    // Create and mount payment request button if available
    const paymentRequestButtonElement = this.elements.create('paymentRequestButton', {
      paymentRequest: this.paymentRequest,
    })

    // Check if the browser supports payment request
    const result = await this.paymentRequest.canMakePayment()
    if (result) {
      paymentRequestButtonElement.mount(this.paymentRequestButtonTarget)
    } else {
      this.paymentRequestButtonTarget.style.display = 'none'
    }

    // Handle payment request completion
    this.paymentRequest.on('paymentmethod', async (ev) => {
      const formData = this.getFormData()

      const { paymentIntent, error: confirmError } = await this.stripe.confirmCardPayment(
        this.clientSecretValue,
        {
          payment_method: ev.paymentMethod.id,
          payment_method_data: {
            billing_details: {
              name: this.userNameValue,
              email: this.userEmailValue
            },
            metadata: formData
          }
        }
      )

      if (confirmError) {
        ev.complete('fail')
        this.errorMessageTarget.textContent = confirmError.message
        return
      }

      ev.complete('success')

      if (paymentIntent.status === 'requires_action') {
        await this.stripe.confirmCardPayment(this.clientSecretValue)
      }

      window.location.href = this.returnUrlValue
    })
  }

  setupEventListeners() {
    // Handle form submission
    this.formTarget.addEventListener('submit', async (e) => {
      e.preventDefault()
      await this.handleSubmit(e)
    })

    // Handle delivery method toggle
    const deliveryMethodInputs = this.formTarget.querySelectorAll('input[name="delivery_method"]')
    deliveryMethodInputs.forEach(input => {
      input.addEventListener('change', (e) => {
        this.deliveryFieldsTarget.style.display = e.target.value === 'delivery' ? 'block' : 'none'
      })
    })
  }

  async handleSubmit(event) {
    event.preventDefault()
    this.setLoading(true)

    const formData = this.getFormData()

    const { error } = await this.stripe.confirmPayment({
      elements: this.elements,
      confirmParams: {
        return_url: this.returnUrlValue,
        payment_method_data: {
          billing_details: {
            name: this.userNameValue,
            email: this.userEmailValue
          },
          metadata: formData
        }
      }
    })

    if (error) {
      this.errorMessageTarget.textContent = error.message
      this.setLoading(false)
    }
  }

  getFormData() {
    return {
      delivery_method: this.formTarget.querySelector('input[name="delivery_method"]:checked').value,
      delivery_address: this.formTarget.querySelector('textarea[name="delivery_address"]')?.value,
      delivery_date: this.formTarget.querySelector('input[name="delivery_date"]')?.value,
      special_instructions: this.formTarget.querySelector('textarea[name="special_instructions"]').value
    }
  }

  setLoading(isLoading) {
    this.submitTarget.disabled = isLoading
    this.buttonTextTarget.classList.toggle('hidden', isLoading)
    this.spinnerTarget.classList.toggle('hidden', !isLoading)
  }
}
