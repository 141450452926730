import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "previewContainer", "submitButton"]
  static values = {
    maxSize: { type: Number, default: 5 }, // Max size in MB
    maxFiles: { type: Number, default: 10 } // Max number of files
  }

  connect() {
    this.uploadCount = 0
    this.loadExistingPreviews()

    // Handle form submission
    const form = this.element.closest('form')
    if (form) {
      form.addEventListener('submit', this.handleSubmit.bind(this))
    }

    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.disabled = true
    }
  }

  loadExistingPreviews() {
    const existingPreviews = this.previewContainerTarget.querySelectorAll('[data-existing-image]')
    existingPreviews.forEach(preview => {
      const signedId = preview.dataset.signedId
      if (signedId) {
        preview.querySelector('.remove-button')?.addEventListener('click', (e) => {
          e.preventDefault()
          this.removeExistingPreview(preview, signedId)
        })
      }
    })
  }

  handleSubmit(event) {
    if (this.uploadCount > 0) {
      event.preventDefault()
      return false
    }
  }

  handleFiles(event) {
    const files = event.target.files
    if (!this.validateFiles(files)) {
      event.target.value = ''
      return
    }

    // Clear existing previews that aren't from server
    const newPreviews = this.previewContainerTarget.querySelectorAll('[data-new-image]')
    newPreviews.forEach(preview => preview.parentElement.remove())

    Array.from(files).forEach(file => {
      if (file.type.startsWith('image/')) {
        this.addPreview(file)
      }
    })

    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.disabled = files.length === 0
    }
  }

  startUpload(event) {
    this.uploadCount += 1
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.disabled = true
      this.submitButtonTarget.textContent = "Uploading..."
    }
  }

  finishUpload(event) {
    this.uploadCount -= 1
    if (this.uploadCount === 0 && this.hasSubmitButtonTarget) {
      this.submitButtonTarget.disabled = false
      this.submitButtonTarget.textContent = "Upload Images"
    }
  }

  validateFiles(files) {
    if (files.length > this.maxFilesValue) {
      alert(`You can only upload up to ${this.maxFilesValue} images`)
      return false
    }

    for (const file of files) {
      if (!file.type.startsWith('image/')) {
        alert(`${file.name} is not an image file`)
        return false
      }

      const sizeInMB = file.size / (1024 * 1024)
      if (sizeInMB > this.maxSizeValue) {
        alert(`${file.name} is too large. Maximum file size is ${this.maxSizeValue}MB`)
        return false
      }
    }

    return true
  }

  addPreview(file) {
    const reader = new FileReader()
    const div = document.createElement('div')
    div.className = 'relative group'

    reader.onload = (e) => {
      div.innerHTML = `
        <div class="relative" data-new-image>
          <img src="${e.target.result}" 
               class="w-full h-48 object-cover rounded-lg" 
               alt="${file.name}">
          <div class="absolute top-2 right-2">
            <button type="button" 
                    class="remove-button bg-red-500 text-white p-1 rounded-full hover:bg-red-600 transition-colors"
                    data-action="click->image-preview#removePreview">
              ×
            </button>
          </div>
          <div class="absolute bottom-2 left-2 right-2">
            <div class="text-sm text-white bg-black bg-opacity-50 rounded p-1">
              ${file.name} (${(file.size / (1024 * 1024)).toFixed(1)} MB)
            </div>
          </div>
        </div>
      `
      this.previewContainerTarget.appendChild(div)
    }

    reader.readAsDataURL(file)
  }

  removePreview(event) {
    event.preventDefault()
    const previewElement = event.target.closest('[data-new-image]')?.parentElement
    if (!previewElement) return

    previewElement.remove()

    // Reset file input if no previews left
    const remainingPreviews = this.previewContainerTarget.querySelectorAll('[data-new-image]')
    if (remainingPreviews.length === 0) {
      this.inputTarget.value = ''
      if (this.hasSubmitButtonTarget) {
        this.submitButtonTarget.disabled = true
      }
    }
  }

  removeExistingPreview(previewElement, signedId) {
    const designId = previewElement.closest('form').getAttribute('action').split('/')[2]

    fetch(`/designs/${designId}/remove_image/${signedId}`, {
      method: 'DELETE',
      headers: {
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
      },
    }).then(response => {
      if (response.ok) {
        previewElement.remove()
      } else {
        alert('Failed to remove image. Please try again.')
      }
    }).catch(error => {
      console.error('Error removing image:', error)
      alert('Failed to remove image. Please try again.')
    })
  }
}
