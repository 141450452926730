import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "content"]

  connect() {
    this.showTab(this.element.dataset.defaultTab || 'pending')
  }

  switch(event) {
    event.preventDefault()
    this.showTab(event.currentTarget.dataset.tabTarget)
  }

  showTab(tabName) {
    // Update active tab styling
    this.tabTargets.forEach(tab => {
      tab.classList.toggle('border-blue-500', tab.dataset.tabTarget === tabName)
      tab.classList.toggle('text-blue-600', tab.dataset.tabTarget === tabName)
    })

    // Show/hide content
    this.contentTargets.forEach(content => {
      content.classList.toggle('hidden', content.dataset.tabName !== tabName)
    })
  }
}
